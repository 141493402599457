<template>
    <v-container fluid style="padding-bottom:200px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="ml-4 mr-5">
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col xs="12" sm="3" md="3" class="col-12">
                <h6 class="blue-lcd mr-5 mt-3" style="font-weight:600">Mill ID</h6>
                <v-autocomplete
                    solo
                    clearable
                    v-model="mill"
                    :items="mills"
                    item-value="mill_id"
                    item-text="mill_name"
                    label="MILL ID"
                    class="border-12"
                    @change="(event) => updateDivisi(event)">
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="3" md="3" class="col-12">
                <h6 class="blue-lcd mr-5 mt-3" style="font-weight:600">Brand</h6>
                <v-autocomplete
                    solo
                    clearable
                    v-model="brand"
                    :items="brands"
                    item-value="str1"
                    item-text="str2"
                    label="Brand"
                    class="border-12"
                    @change="(event) => updateMachType(event)">
                ></v-autocomplete>
            </v-col>
               <v-col xs="8" sm="3" md="3" class="col-2">
                <h6 class="blue-lcd mr-5 mt-3" style="font-weight:600">Division</h6>
                <v-autocomplete
                    solo
                    clearable
                    v-model="divisi"
                    :items="divis"
                    item-value="class"
                    item-text="descr"
                    label="Division"
                    class="border-12"
                     @change="(event) => updateMachType(event)">
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="3" md="3" class="col-12">
                <h6 class="blue-lcd mr-5 mt-3" style="font-weight:600">Machine Type</h6>
                <v-autocomplete
                    solo
                    clearable
                    v-model="machine"
                    :items="machines"
                    item-value="mach_type"
                    item-text="descr"
                    label="Machine Type"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
        
             <v-col xs="12" sm="3" md="3" class="col-12">
                <v-btn class="border-12 text-white p-4 mt-10" color="primary" @click="search(1,20)">Search</v-btn>
                <v-btn class="border-12 text-white p-4 mt-10 ml-3" color="secondary" @click="clear()">Clear</v-btn>
            </v-col>
        </v-row>
       <v-row class="mt-8" v-show="display">
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>
                                    Result
                                    <v-spacer></v-spacer>
                                    <v-btn 
                                        class="pa-5"
                                        small
                                        color="primary"
                                        :disabled="disabledExport"
                                    >
                                        <download-excel
                                            :fetch           = "exportExcel"
                                            :fields="header_field"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            <v-icon>
                                                mdi-file-excel
                                            </v-icon>
                                            Export to Excel
                                        </download-excel>  
                                        
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                              <tr>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Divisi</td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Type Produk</td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Jumlah Mesin</td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Kapasitas Perjam (MTR)</td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">Kapasitas Pershift (MTR)</td>
                                                <td scope="col" colspan="2" style="vertical-align: middle;text-align: center;">
                                                  RS
                                                </td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">
                                                  Total Backlog (MTR)
                                                </td>
                                                <td  scope="col" rowspan="2" style="vertical-align:  middle;text-align: center">
                                                    Total Jam Backlog Rs N
                                                </td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">
                                                    Total Jam Backlog Rs Y
                                                </td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">
                                                    Total Hari Rs N
                                                </td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">
                                                    Total Hari Rs Y
                                                </td>
                                                <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">
                                                    Total Hari Backlog Rs Y & N
                                                </td>
                                                <!-- <td scope="col" rowspan="2" style="vertical-align: middle;text-align: center;">

                                                    Keterangan Mesin Jalan
                                                </td> -->
                                              </tr>
                                              <tr>
                                                <td scope="col" style="text-align: center;">N</td>
                                                <td scope="col" style="text-align: center;">Y</td>
                                              </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in coilratios" :key="index">
                                              <tr class="table-active">
                                                  <td scope="col" colspan="15" style="font-weight: 600;">
                                                      {{item.mill_name}}
                                                  </td>
                                              </tr>
                                              <tr v-for="(devisi, i) in item.divisi" :key="i">
                                                <td scope="col" style="text-align: center;">{{devisi.division}}</td>
                                                <td scope="col" style="text-align: left;">{{devisi.namaMesin}}</td>
                                                <td scope="col" style="text-align: center;">{{$store.getters.convertToCurrencyUs(devisi.jmlMesin)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.cpHour)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.cpShift)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.RS_N)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.RS_Y)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.Tot_RS)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.BL_N_Hour)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.BL_Y_Hour)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.BL_N_Day)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.BL_Y_Day)}}</td>
                                                <td scope="col" style="text-align: right;">{{$store.getters.convertToCurrencyUs(devisi.Tot_BL_Day)}}</td>
                                                <!-- <td scope="col" style="text-align: right;">{{devisi.Keterangan}}</td> -->
                                              </tr>
                                            </tbody> 
                                        </table>
                                    </div>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import {backendApi} from "../../../backend-api/backend-api-sr"

    export default {
        data() {
            return {
                breadcumbs: [
                    {
                    text: 'GBRK',
                    disabled: false,
                    href: '/admin/gbrk',
                    },
                    {
                    text: 'Prod. Performa',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Machine Backlog',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                mills: [],
                mill: '',
                brands: [],
                brand: '',
                date: null,
                date_field: new Date().toISOString().substr(0, 4),
                menu: false,
                year: new Date().toISOString().substr(0, 4),
                picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                sortBy: 'asc',
                sortName: '',
                headers: [
                    { text: 'Mill', value: 'mill_name', sortable: false },
                    { text: 'Tahun', groupable: false, value: 'tahun', sortable: false },
                    { text: 'Bulan', groupable: false, value: 'bulan', sortable: false },
                    { text: 'TOT_PRM', groupable: false, value: 'TOT_PRM', sortable: false },
                    { text: 'RATIO_TOT_PRM', groupable: false, value: 'RATIO_TOT_PRM', sortable: false },
                    { text: 'TOT_NON', groupable: false, value: 'TOT_NON', sortable: false },
                    { text: 'RATIO_TOT_NON', groupable: false, value: 'RATIO_TOT_NON', sortable: false },
                    { text: 'ATAP_PRM', groupable: false, value: 'ATAP_PRM', sortable: false },
                    { text: 'RATIO_ATAP_PRM', groupable: false, value: 'RATIO_ATAP_PRM', sortable: false },
                    { text: 'ATAP_NON', groupable: false, value: 'ATAP_NON', sortable: false },
                    { text: 'RATIO_ATAP_NON', groupable: false, value: 'RATIO_ATAP_NON', sortable: false },
                    { text: 'TRUSS_PRM', groupable: false, value: 'TRUSS_PRM', sortable: false },
                    { text: 'RATIO_TRUSS_PRM', groupable: false, value: 'RATIO_TRUSS_PRM', sortable: false },
                    { text: 'TRUSS_NON', groupable: false, value: 'TRUSS_NON', sortable: false },
                    { text: 'RATIO_TRUSS_NON', groupable: false, value: 'RATIO_TRUSS_NON', sortable: false },
                    { text: 'HOLLOW_PRM', groupable: false, value: 'HOLLOW_PRM', sortable: false },
                    { text: 'RATIO_HOLLOW_PRM', groupable: false, value: 'RATIO_HOLLOW_PRM', sortable: false },
                    { text: 'HOLLOW_NON', groupable: false, value: 'HOLLOW_NON', sortable: false },
                    { text: 'RATIO_HOLLOW_NON', groupable: false, value: 'RATIO_HOLLOW_NON', sortable: false },
                    { text: 'GENTING_PRM', groupable: false, value: 'GENTING_PRM', sortable: false },
                    { text: 'RATIO_GENTING_PRM', groupable: false, value: 'RATIO_GENTING_PRM', sortable: false },
                    { text: 'GENTING_NON', groupable: false, value: 'GENTING_NON', sortable: false },
                    { text: 'RATIO_GENTING_NON', groupable: false, value: 'RATIO_GENTING_NON', sortable: false },
                ],
                coilratios:[],
                options: {},
                loading: false,
                totalItems:20,
                pagination:1,
                disabledExport: false,
                header_field: {
                    'mill_id' : 'mill_name',
                    'mill_name' : 'mill_name',
                    'division' : 'division',
                    'mesin' : 'mesin',
                    'namaMesin' : 'namaMesin',
                    'jmlMesin' : 'jmlMesin',
                    'shift' : 'shift',
                    'cpHour' : 'cpHour',
                    'cpShift' : 'cpShift',
                    'cpDay' : 'cpDay',
                    'RS_N' : 'RS_N',
                    'RS_Y' : 'RS_Y',
                    'Tot_RS' : 'Tot_RS',
                    'BL_N_Hour' : 'BL_N_Hour',
                    'BL_Y_Hour' : 'BL_Y_Hour',
                    'Tot_BL_Hour' : 'Tot_BL_Hour',
                    'BL_N_Shift' : 'BL_N_Shift',
                    'BL_Y_Shift' : 'BL_Y_Shift',
                    'Tot_BL_Shift' : 'Tot_BL_Shift',
                    'BL_N_Day' : 'BL_N_Day',
                    'BL_Y_Day' : 'BL_Y_Day',
                    'Tot_BL_Day' : 'Tot_BL_Day',
                    'Keterangan' : 'Keterangan',
                    'class' : 'class'
                },
                display: false,
                machines: [],
                machine: '',
                divis: [],
                divisi:''


            }
        },
        async mounted(){
            this.$store.dispatch('setOverlay', true)
            await this.getEnvConf()
            await this.getMills()
            await this.getBrand()
            await this.getMachineType()
            await this.getDivisi()
            this.$store.dispatch('setOverlay', false)
            this.$refs.picker.activePicker = 'YEAR'
        },
        methods:{
            async getEnvConf(){
                const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=GBRK&appl_id=WEBGBRK&var_id=MILLID&groupid=${this.$store.state.kka.group_id}`, null, false, false, false) 

                if (respVar.data.data != null) {
                    this.mill = respVar.data.data
                } else {
                    this.mill = ''
                }
            },
            save(date) {
                console.log(date);

                this.$refs.menu.save(date)

                // Reset activePicker to type YEAR
                this.$refs.picker.activePicker = 'YEAR'

                // Close the menu/datepicker
                this.menu = false

                this.date_field = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
            },
            async getMills(){
                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/mill?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    // console.log(res.data)
                    this.mills = res.data.data
                });
            },
            async getBrand(){
                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/machine_backlog/brand`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    ///console.log(res.data)
                    this.brands = res.data.data
                });
            },
            async getMachineType(){
                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/machine_type?machine_type=${this.machine_type ? this.machine_type : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    ///console.log(res.data)
                    this.machines = res.data.data.data
                });
            },

            async updateMachType(id){

              await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/machine_type?mill_id=${this.mill ? this.mill  : ''}&class=${this.divisi ? this.divisi : ''}&category_id=${this.brand ? this.brand : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    ///console.log(res.data)
                    this.machines = res.data.data.data
                });

            },

             async getDivisi(){
                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/division?division=${this.division ? this.division : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                  console.log(res.data)
                    this.divis = res.data
                });
            },

              async updateDivisi(id){

              await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/master/division?mill_id=${this.mill ? this.mill : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    ///console.log(res.data)
                    this.divis = res.data.data.data
                });

            },

            sortByFunc(sortBy){
                console.log(this.sortBy, sortBy);
                this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
                this.sortName = sortBy
                this.search(1, 20)
            },
            async search(){
                // console.log(this.mill);
                // console.log(this.date);
                this.loading = true
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/MachineBacklog?mill_id=${this.mill ? this.mill : ''}&brand=${this.brand ? this.brand : ''}&division=${this.divisi ? this.divisi : ''}&mesin=${this.machine ? this.machine : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.display = true
                    this.loading = false
                    this.$store.dispatch('setOverlay', false)

                    this.coilratios = res.data
                })
            },
            async exportExcel(){
                var url = `${process.env.VUE_APP_URL}/api/gbrk/MachineBacklog/export-excel?mill_id=${this.mill ? this.mill : ''}&brand=${this.brand ? this.brand : ''}&division=${this.divisi ? this.divisi : ''}&mesin=${this.machine ? this.machine : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}`
                const response = await axios.get(url, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                return response.data;
            },
            startDownload(){
                this.loading = true
                this.$store.dispatch('setOverlay', true)

                this.disabledExport=true
            },
            finishDownload(){
                this.loading = false
                this.$store.dispatch('setOverlay', false)

                this.disabledExport=false
            },
            clear(){
                this.date = null
                this.mill = ''
                this.divisi = ''
                this.machine =''
                this.date_field = null
            }
        },
        watch: {
            menu (val) {
                console.log(val);
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
            // options: {
            //     handler () {
            //         const { page, itemsPerPage } = this.options
            //         this.search(page, itemsPerPage)
            //     },
            //     deep: true,
            // },
        }
    }
    </script>
    <style>
    
element.style {
    vertical-align: middle;
    text-align: center;
    background-color: aquamarine;
}
    </style>